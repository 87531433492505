import React from 'react';
// import heroImage from '../../assets/images/profile.png';
import '../../css/LandingPage/HeroSection.css';
import cv from '../../assets/cv.pdf';

function HeroSection() {
    return (
        <section id="home" className="firstsection bg-black text-light">
            <div className="container h-100 custom-padding">
                <div className="row h-100 align-items-center justify-content-center">
                    <div className="col-lg-7">
                        <div>
                            <h5 className="text-center text-lg-start">Hi There! My Name Is</h5>
                            <h1 className="fw-bold text-center text-lg-start">Utkarsh Agarwal</h1>
                            <p className="lead mb-4 text-center text-lg-start">
                                I'm a passionate Frontend Web Developer and AI enthusiast, skilled in Python, Data Visualization, NLP, and Deep Learning, dedicated to merging creativity with technology to drive innovative, efficient solutions in website and application design, and constantly pushing boundaries to shape the future of tech.
                            </p>
                            <div className="d-flex justify-content-center justify-content-lg-start gap-2">
                                <a href="https://www.linkedin.com/in/utkarshagarwal97" target="_blank" rel="noreferrer">
                                    <button type="button" className="btn btn-custom btn-lg px-4">Let's Connect on LinkedIn</button>
                                </a>
                                <a href={cv} target="_blank" rel="noreferrer">
                                    <button type="button" className="btn btn-custom btn-lg px-4">Download my CV</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 d-none d-lg-block">
                        <img src='/brand/profile.png' alt="Utkarsh Agarwal" className="img-fluid p-5" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HeroSection;
